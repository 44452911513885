.dz-release-code {
    padding: 0.2em 0.4em;
    margin: 0 4px;
    font-size: 85%;
    white-space: break-spaces;
    background-color: rgb(110 118 129 / 15%);
    border-radius: 6px;
}

.dz-release-td, .dz-release-th {
    background-color: #fafafa;
    border-right: 1px solid #c3c3c39e;
    text-align: center !important;
}

:is(.dz-release-td,.dz-release-th):last-child {
    border-right: 0;
}

.dz-release-th {
    border-bottom: 1px solid #c3c3c39e;
}

.dz-release-menu {
    height: calc(76vh - 10px);
    overflow-x: auto;
}
.dz-release-menu div{
    height: calc(76vh - 84px);
}

.dz-release-modal-content {
    height: calc(76vh - 10px);
    overflow-x: auto;
    position: relative;
}

.dz-release-card {
    height: calc(76vh - 84px);
    position: relative;
    padding: 12px;
    overflow-y: auto;
    width: 100%;
}